export function getImageTypeByBase64Header (base64Header: string): string {
  if (base64Header === '/9') {
    return 'jpeg';
  }
  if (base64Header === 'iV') {
    return 'png';
  }
  if (base64Header === 'Qk') {
    return 'bmp';
  }
  return '';
}

export function getImageUrl (imageBase64: string): string {
  return `data:image/${getImageTypeByBase64Header(imageBase64.substring(0, 2))};base64,${imageBase64}`;
}
